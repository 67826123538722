.head {
  display: flex;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #d9e4ef;
  align-items: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 1px 8px 0px rgba(0, 0, 0, 0.02);
  user-select: none;
  cursor: pointer;
}

.openedDetails .head {
  background-color: #f1f9fc;
  border-color: #01b9e7;
  box-shadow: none;
}

.head > svg:first-child {
  margin-right: 16px;
  color: #104772;
}

.head > div {
  color: #1a1a1a;
  font-weight: 500;
  flex: 1;
}

.head > svg:last-child {
  display: block;
  color: #a1aab0;
}

.openedDetails .head > svg:last-child {
  transform: rotate(180deg);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list > li + li {
  border-top: 1px solid #d9e4ef;
}
