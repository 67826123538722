.loadWrap{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 25%;
}

.loadButton{
    border:none;
    background-color:transparent;
    color: red;
}

.loadProgressWrap{
    margin-top: 30px;
}
