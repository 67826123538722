.body {
  display: block;
  position: relative;
  border-radius: 2px;
  height: 4px;
  background-color: #D9E4EF;
}
.filled {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  height: 4px;
  background-color: #01B9E7;
}