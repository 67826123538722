.wrapper {
  margin: 16px 0 8px;
}

.button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9e4ef;
  border-radius: 4px;
  padding: 18px;
  color: #01b9e7;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.button:active {
  border-color: #01b9e7;
}

@media (hover: hover) {
  .button:hover {
    border-color: #01b9e7;
  }
}

.button svg {
  margin-right: 8px;
}

.uploader {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.image {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
}

.image > img {
  max-height: 60vw;
  max-width: 100%;
}

.image > div {
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  padding: 2px;
  background-color: #a1aab0;
  color: #ffffff;
  border-radius: 50%;
}
