.form_company {
  width: 100%;
}
.form_wrapper {
  margin: 24px 0;
}
.form_wrapper > div {
  width: calc((100% - 45px) / 3);
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 928px) {
  .form_wrapper > div {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.form_buttons {
  display: flex;
  justify-content: flex-end;
}

.form_container {
  display: flex;
  flex-direction: row;
}

.form_map {
  max-height: 55px;
  padding-right: 12px;
}
.card_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_buttons > * + * {
  margin-left: 16px;
}

.rows_container {
  display: flex;
  flex-flow: row;
}
@media screen and (max-width: 928px) {
  .rows_container {
    height: auto;
    flex-flow: column;
  }
}

.rows_container > * {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.button_holder {
  float: right;
  margin-left: 10px;
}

.map_btn {
  height: 38px;
}

.workHoursVisibility {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
}

.workHoursNew {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.workHoursNew2 {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 50px;
}

.segmented-control label {
  margin-bottom: 0;
}

.select__menu {
  z-index: 101 !important;
}
