.wrapper {
  margin: 16px 0;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    0px 1px 8px 0px rgba(0, 0, 0, 0.02);
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 4px 0 16px;
}

.header > label {
  margin: 0;
  font-weight: 500;
  color: #1a1a1a;
}

.header > button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  font-weight: 500;
  color: #01b9e7;
}

.header > button[disabled] {
  color: #b7c8d5;
}

.wrapper > textarea {
  height: 84px;
}
