.formWrapper {
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
}

.stepTitle {
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 16px 0 24px;
}

.sectionLabel {
  display: block;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: #a1aab0;
  margin: 16px 0;
}

.horizSplitter {
  color: #d9e4ef;
  margin: 24px -20px;
}

.button {
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  background-color: transparent;
  color: #104772;
}

.button:focus {
  outline: none;
}

.button svg {
  margin-right: 8px;
}

.buttonSecondary {
  color: #104772;
}

.buttonPrimary {
  background-color: #104772;
  color: #ffffff;
}

.buttonPrimary[disabled] {
  background-color: #b7c8d5 !important;
}

.buttonOutlined {
  border: 1px solid #d9e4ef;
  color: #01b9e7;
}

.buttonPrimary:active {
  background-color: #01b9e7;
}
.buttonSecondary:active {
  background-color: #f1f9fc;
}
.buttonOutlined:active {
  border-color: #01b9e7;
}
@media (hover: hover) {
  .buttonPrimary:hover {
    background-color: #01b9e7;
  }
  .buttonSecondary:hover {
    background-color: #f1f9fc;
  }
  .buttonOutlined:hover {
    border-color: #01b9e7;
  }
}

.textField {
  display: block;
  width: 100%;
  min-width: 0;
  border: 1px solid #d9e4ef;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin: 8px 0;
  padding: 8px 16px;
  color: #1a1a1a;
}

.textField:focus {
  outline: none;
  border-color: #01b9e7 !important;
}

.textField::placeholder {
  color: #a1aab0;
}

textarea.textField {
  resize: none;
}

*:not(textarea).textField {
  height: 40px;
}

.actionsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
}

.selected {
  background-color: #f1f9fc;
  border-color: #01b9e7 !important;
  box-shadow: none !important;
}

.groupWrapper + .groupWrapper {
  margin-top: 8px;
}

.serviceListItem {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
  cursor: default;
  user-select: none;
  cursor: pointer;
}

.paymentMethodItem {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
  cursor: default;
  user-select: none;
  cursor: pointer;
}

.paymentMethodItem > div:first-child {
  display: flex;
  align-items: center;
}

.paymentMethodItem > div:first-child > svg {
  margin-right: 16px;
}

.timeRangeCnt {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (max-width: 360px) {
  .timeRange span {
    margin: 3px;
  }

  .timeRange select {
    font-size: 14px;
  }
}

.workHoursVisibility {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
  margin-left: 10px;
}
.workHoursVisibilityHour {
  margin-right: 10px;
  font-size: 14px;
}
.flexMix {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: #a1aab0;
}

.serviceTypesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}

.serviceTypeCard {
  height: 100%;
  font-weight: 400;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #d9e4ef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.serviceTypeCard > svg {
  color: #104772;
  margin-bottom: 4px;
}

.serviceTypeCard > div:last-child {
  font-size: 14px;
  line-height: 18px;
  color: #1a1a1a;
}

.languagesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 8px;
}

.languagePill {
  display: inline-flex;
  font-weight: 400;
  padding: 11px;
  border-radius: 99999px;
  border: 1px solid #d9e4ef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  line-height: 14px;
  color: #1a1a1a;
  cursor: pointer;
  user-select: none;
}

.languagePill svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.priceLevelsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  gap: 8px;
}

.priceLevelPill {
  display: inline-block;
  font-weight: 400;
  padding: 11px;
  border-radius: 99999px;
  border: 1px solid #d9e4ef;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
  font-size: 14px;
  line-height: 14px;
  color: #1a1a1a;
  text-align: center;
  cursor: pointer;
  user-select: none;
  min-width: 70px;
}

.weekdayItem {
  padding: 24px 0;
}

.weekdayItem > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weekdayItem > div:nth-child(2) {
  margin-top: 12px;
}
