.rangeWrapper {
  display: flex;
}

.rangeWrapper > * {
  flex: 1;
}

.rangeWrapper > * + * {
  margin-left: 32px;
}

.divis {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  color: #1a1a1a;
  font-weight: 500;
}

.title {
  color: #1a1a1a;
  font-weight: 650;
}
.work_hours {
  margin: 20px 0;
}

.dcSwitch {
  flex: 0 0 50px;
}
