.full {
  min-height: 100vh;
  background-color: #f7f9fa;
}

/* variables */

:root {
  --primary-color: #104772;
}

.pageWrapper {
  padding: 0 24px 32px;
}

.pageTitle {
  margin: 0 8px;
}

.form-group > label {
  font-weight: 600;
}

*:focus {
  outline: 0 !important;
}

.z-100 {
  z-index: 100;
}

.max-w-240 {
  max-width: 240px;
}
