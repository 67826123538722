.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.main {
    height: 600px;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input {
  height: 48px;
  width: 320px;
  background: #FFFFFF;
  border: 1px solid #07B9E6;
  border-radius: 4px;
}

.button {
  height: 56px;
  background: #104772;
  border-radius: 8px;
}

.l-h{
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #104772;
}
