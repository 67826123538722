.mainNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
}

.panelImg {
    width: 30px;
    height: 30px;
    margin-left: 25px;
}

.panelLogout{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-right: 25px;
    margin-left: 50px;
    color: #104772;
    border: none;
    background-color: transparent;
}

.panelLogoutImg{
    width: 16px;
    height: 16px;
    margin-left: 8px;
}