.input-loader {
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  font-size: 10px;
}

.form-group {
  &--mod-1 {
    position: relative;
  }
}
