.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.wrapper > select {
  display: block;
  width: 100%;
  min-width: 0;
  border: none;
  background: none;
  color: #1a1a1a;
  flex: 1;
  text-align: center;
  text-align-last: center;
  border: 1px solid #d9e4ef;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin: 8px 0;
  appearance: initial;
  padding: 4px 0;
}

.wrapper > select:focus {
  outline: none;
  border-color: #01b9e7 !important;
}

.wrapper > select::placeholder {
  color: #a1aab0;
}

.wrapper > span {
  margin: 0 12px;
}
