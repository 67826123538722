.wrapper {
  display: flex;
  color: #a1aab0;
  align-items: center;
  margin: 24px 0;
}

.wrapper svg {
  flex-shrink: 0;
  margin-right: 16px;
}

.wrapper span {
  font-size: 14px;
  line-height: 18px;
}
