//sf - service form
.sf-item {
  height: 90px;

  &--selected {
    justify-content: flex-start;
    padding: 10px 4px;
    background: transparent;
  }

  &__title {
    color: #1a1a1a;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;
    height: 44px;
    background: #f4f5f7;
    border: 1px solid #d9e4ef;
    border-radius: 4px;
    &-btn {
      background-color: transparent;
      border: none;
      background-position: center;
      background-repeat: no-repeat;
      width: 30px;
      padding: 0;
      &--minus {
        background-image: url('../../../../media/icons/minus.svg');
      }
      &--plus {
        background-image: url('../../../../media/icons/plus.svg');
      }
    }
    &-input {
      width: 34px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #1a1a1a;
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.sf-item-icon {
  position: relative;
  &__label {
    color: #104772;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.4px;

    position: absolute;
    right: -18px;
    top: -10px;
  }

  svg {
    g {
      clip-path: none;
    }
  }
}
